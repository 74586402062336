
export default {
  name: 'icon-logo-ru-manas',
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    withoutText: {
      type: Boolean,
      default: false,
    },
  },
};
